import React, { useEffect, useRef, useLayoutEffect } from "react"

import { Link } from "gatsby"

import * as styles from "./Categories.module.css"

const Categories = ({
  memes,
  memeCount,
  currentPageTitle,
  toggleHeaderPartVisibility,
}) => {
  const currentCategoryButtonRef = useRef(null)
  const headingRef = useRef(null)

  const filterOptions = []

  memes.forEach(meme => {
    if (meme.themes) {
      meme.themes.map(theme => {
        if (
          !filterOptions.some(filter => {
            return filter.label === theme
          })
        ) {
          filterOptions.push({
            label: theme,
            value: convertThemeToSlug(theme),
          })
        }
        return false
      })
    }
  })

  filterOptions.sort((a, b) => {
    if (a.label === "All Memes") {
      return -1
    } else if (b.label === "All Memes") {
      return 1
    }
    if (a.value < b.value) {
      return -1
    }
    if (a.value > b.value) {
      return 1
    }
    return 0
  })

  function convertThemeToSlug(theme) {
    const allowedChars =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    let slug = ""
    for (let i = 0; i < theme.length; i++) {
      if (allowedChars.includes(theme[i])) {
        slug += theme[i].toLowerCase()
      } else if (theme[i] === " ") {
        slug += "-"
      }
    }
    return slug
  }

  useLayoutEffect(() => {
    const ios = !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent)

    !ios && toggleHeaderPartVisibility(true)

    if (currentCategoryButtonRef.current) {
      currentCategoryButtonRef.current.scrollIntoView({
        behavior: "auto",
        block: "nearest",
        inline: "start",
      })
    }

    setTimeout(() => {
      if (window.location.pathname === "/") return

      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "auto",
      })

      headingRef.current.scrollIntoView({
        behavior: "auto",
        block: "nearest",
        inline: "start",
      })

      toggleHeaderPartVisibility(false)
    }, 1)
  }, [currentPageTitle])

  return (
    <div className={styles.filter} ref={headingRef} id="c">
      <div className={styles.browseText}>
        <p>Browse Categories</p>
        <div className={styles.lineDeco}></div>
      </div>
      <div className={styles.filterPills}>
        <Link to="/">
          <button className={currentPageTitle === "" ? styles.selected : ""}>
            All Memes ({memeCount})
          </button>
        </Link>
        {filterOptions.map((filter, index) => {
          return (
            <Link key={index} to={`/memes/${filter.value}`}>
              <button
                className={
                  currentPageTitle === filter.label ? styles.selected : ""
                }
                ref={
                  currentPageTitle === filter.label
                    ? currentCategoryButtonRef
                    : null
                }
              >
                {filter.label}
              </button>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default Categories
