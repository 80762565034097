import React, { useState, useEffect } from "react"
import { Plock } from "react-plock"
import { Helmet } from "react-helmet"

import Header from "../components/Header"
import Footer from "../components/Footer"
import MemeCard from "../components/MemeCard"
import Categories from "../components/Categories"

import profilePicture from "../images/dago.png"
import * as styles from "./AllMemes.module.css"
import * as styles2 from "./JourneyPage.module.css"

const AllMemes = ({ pageContext }) => {
  const { memes, memeCount } = pageContext

  const [currentPageCount, setCurrentPageCount] = useState(1)
  const [visibleMemes, setVisibleMemes] = useState(
    memes.slice(0, currentPageCount * 20)
  )
  const [categoriesVisible, setCategoriesVisible] = useState(true)
  const [searchQuery, setSearchQuery] = useState("")

  const breakpoints = [
    { size: 640, columns: 1 },
    { size: 768, columns: 2 },
    { size: 1024, columns: 3 },
  ]

  const clearHandler = () => {
    setSearchQuery("")
    setVisibleMemes(memes.slice(0, currentPageCount * 20))
  }

  const setLastViewedMeme = memeId => {
    sessionStorage.setItem("lastViewedMeme", memeId)
  }

  useEffect(() => {
    if (currentPageCount === 1) {
      setCurrentPageCount(parseInt(sessionStorage.getItem("pageCount")) || 1)
    }
  }, [])

  useEffect(() => {
    window.sessionStorage.setItem("pageCount", currentPageCount)
  }, [currentPageCount])

  useEffect(() => {
    if (sessionStorage.getItem("lastViewedMeme")) {
      window.location.href =
        window.location.href.split("#")[0] +
        `#${sessionStorage.getItem("lastViewedMeme")}`
    } else {
      setCurrentPageCount(1)
      sessionStorage.setItem("pageCount", 1)
    }
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchQuery === "") {
        clearHandler()
        setCurrentPageCount(1)
        sessionStorage.setItem("pageCount", 1)
        setVisibleMemes(memes.slice(0, currentPageCount * 20))
      }
      const filteredMemes = memes.filter(meme => {
        if (meme.caption.toLowerCase().includes(searchQuery.toLowerCase())) {
          return true
        }
        return (
          meme.themes &&
          meme.themes.some(theme => {
            if (theme.toLowerCase().includes(searchQuery.toLowerCase())) {
              return true
            }
            return false
          })
        )
      })
      setVisibleMemes(
        filteredMemes
          .sort((a, b) => b.tweetPerformance.likes - a.tweetPerformance.likes)
          .slice(0, currentPageCount * 20)
      )
    }, 500)

    return () => clearTimeout(timer)
  }, [searchQuery])

  useEffect(() => {
    if (searchQuery) {
      setCategoriesVisible(false)
    } else {
      setCategoriesVisible(true)
    }
  }, [visibleMemes])

  useEffect(() => {
    if (currentPageCount > 1 && !searchQuery) {
      setVisibleMemes(memes.slice(0, currentPageCount * 20))
    }
  }, [currentPageCount])

  useEffect(() => {
    const onScroll = function () {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 300
      ) {
        setCurrentPageCount(currentPageCount => currentPageCount + 1)
      }
    }
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  return (
    <>
      <Header />
      <Helmet>
        <title>Startup memes by memelogy</title>
        <meta
          name="description"
          content="Fun memes from the startup journey."
        />
      </Helmet>
      <section className={styles.memes}>
        <div className={styles.container}>
          <div className={styles2.intro}>
            <img src={profilePicture} alt="Dagobert" />
            <p>
              Hi, I'm Dagobert 👋
              <br />I build startups, create memes, and teach founders{" "}
              <a href="https://www.dagorenouf.com/how-to-dominate-twitter/">
                how to grow on twitter/x
              </a>
              .
            </p>
          </div>
          {/* <h1>Meme Database</h1> */}
          {/* <div className={styles.searchbar}>
                        <input type="text" placeholder="Search" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                        <button onClick={clearHandler} style={{
                            opacity: !categoriesVisible ? 1 : 0,
                            visibility: !categoriesVisible ? 'visible' : 'hidden',
                        }}>clear</button>
                    </div> */}
          {categoriesVisible && (
            <Categories
              memes={memes}
              memeCount={memeCount}
              currentPageTitle=""
              toggleHeaderPartVisibility={() => {}}
            />
          )}
          {typeof window !== "undefined" && (
            <div
              style={{
                minHeight: visibleMemes.length > 10 ? "auto" : "50vh",
              }}
            >
              <Plock nColumns={breakpoints} gap="2rem">
                {visibleMemes.map((meme, index) => (
                  <MemeCard
                    key={index}
                    id={meme.id}
                    type={meme.type}
                    poster={meme.poster}
                    media={meme.media}
                    date={meme.date}
                    themes={meme.themes}
                    themeColor={meme.themeColor}
                    setLastViewedMeme={() => setLastViewedMeme(meme.id)}
                  />
                ))}
              </Plock>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </>
  )
}

export default AllMemes
